import { createStore } from 'vuex'
import { auth } from './auth.module';
import {request}  from './request.module';
export default createStore({
  state: {
    machineName:'SUMED', //label of machine
    midDi0:1,          ///1:4l    3:1l
    midDo4:5,          ///5:4l    6:1l
    midMaintMode:100,  ///100:4l  102:1l
    midDo0:1,          ///1:4l    3:1l
    vardeleyMuliplier:4, /// 188:4l  45:1l
    vardelayCarton:11,   /// 11:4l   22:1l   ///the two machines is 1
    midLockMode:501 ,    ///501:4l  503:4l   .........got to message to dispaly if loack signal is on
    mtimer:6001,           ///timer after finish   4l:6001----in vardelay  20l:6002----in vardelay
    sbatch:[],//{id:'',runid:'',startdate:'',enddate:'',dayend:'',sapid:'',productcode:'',empno:''},
    midTagetPei:1004,   //target PEI of 4l 1004, 1l:1001
    midTagetRate:1104,  //target rate of 4l 1104, 1l:1101
    unitperCarton:4,    //4 for 4l  , 12 for 1l
    atfproduct:'atf',
    specialmode:5,
    reqs:'',
    reqs2:'',

  },
  getters: {
    getreq(reqs){
     return reqs.reqs;
    },
  },
  mutations: {
    getreqsm(state,data){

      state.reqs=data;
     // alert( state.reqs.unitid);
    }
  },
  actions: {

    getreqs({commit},data){
      commit('getreqsm', data);
  },
  // getmyreq({commit}){
  //   $soketio.on('CH01', (data) => {
  //     state.reqs2=(data);
  //   })
  // },
  },
  modules: {
    auth,
    request
  }
})
