import { createRouter, createWebHistory } from '@ionic/vue-router';
//import { RouteRecordRaw } from 'vue-router';
//import HomePage from '../views/HomePage.vue'




import TabsPage from '../views/TabsPage.vue'

const routes= [
  {
  path: '/',
  redirect: '/tabs/tab2'
},
{
  path: '/tabs/',
  component: TabsPage,
  children: [
    {
      path: '',
      redirect: '/tabs/tab2'
    },
    {
      path: 'tab1',
      component: () => import('@/views/Tab1Page.vue')
    },
    {
      path: 'tab2',
      component: () => import('@/views/Tab2Page.vue')
    },
    {
      path: 'tab3',
      component: () => import('@/views/Tab3Page.vue')
    },
    {
      path: 'tab4',
      component: () => import('@/views/Register.vue')
    }
  ]
}
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //history: createWebHistory('https://sumedapi.egyptiot.com:3093'),
  routes
})

export default router


// {
//   path: '/',
//   redirect: '/tabs/tab2'
// },
// {
//   path: '/tabs/',
//   component: TabsPage,
//   children: [
//     {
//       path: '',
//       redirect: '/tabs/tab1'
//     },
//     {
//       path: 'tab1',
//       component: () => import('@/views/Tab1Page.vue')
//     },
//     {
//       path: 'tab2',
//       component: () => import('@/views/Tab2Page.vue')
//     },
//     {
//       path: 'tab3',
//       component: () => import('@/views/Tab3Page.vue')
//     }
//   ]
// }
