import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from "./store";
import { IonicVue } from '@ionic/vue';
import axios from "axios";
import "./filters";
import "./directives";
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import MasterLayout from './components/MasterLayout.vue';
/* Theme variables */
import './theme/variables.css';
import VeeValidate from "vee-validate";
//createApp.use(VeeValidate);

const app = createApp(App)
app.component('master-layout', MasterLayout);
// //var io = require('socket.io')
// (http,{
//   cors: {
//      origins: '*:*',
//const socket = io('ws://localhost:3000')
import io from 'socket.io-client';
import authHeader from './services/auth-header';
//import socketio from 'socket.io';
import VueSocketIO from 'vue-socket.io';
app.config.globalProperties.$soketio = io('https://sumedapi.egyptiot.com' )//,cors:{origins:'*:*'})
//app.config.globalProperties.$soketio = io('http://192.168.1.8:3093')//,cors:{origins:'*:*'})

  app.use(IonicVue)
  app.use(store)
  //app.use(VeeValidate)egyptiot.com
  app.use(router);
  //app.mount('#app')
  //app.config.globalProperties.$soketio = io('localhost:3091');
//   app.use(store).use(router).mount('#app')
router.isReady().then(() => {
  app.mount('#app');
});
