<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button  v-if="showModeratorBoard" tab="tab1" href="/tabs/tab1">
          <ion-icon :icon="triangle" />
          <ion-label>Requests</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab2" href="/tabs/tab2">
          <ion-icon :icon="ellipse" />
          <ion-label>Login</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab3"  v-if="showOpBoard" href="/tabs/tab3">
          <ion-icon :icon="square" />
          <ion-label>Driver</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="tab4"  v-if="showAdminBoard" href="/tabs/tab4">
          <ion-icon :icon="square" />
          <ion-label>Admin</ion-label>
        </ion-tab-button>
        <!-- <ion-tab-button tab="tab3"  >
            <ion-icon :icon="square" />
             <ion-label>{{}}</ion-label>
        </ion-tab-button> -->
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import { defineComponent } from 'vue';
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { ellipse, square, triangle } from 'ionicons/icons';
//import { METHOD_TYPES } from '@babel/types';

export default defineComponent({
  name: 'TabsPage',
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {
    return {
      ellipse,
      square,
      triangle,
    }
  },
// methods:{
  // mounted() {
  //   if (this.loggedIn & showModeratorBoard) {
  //     this.$router.push("/tabs/tab1");
  //   }
  //   else{
  //      this.$router.push("/tabs/tab3");
  //   }
  // },
computed:{
   loggedIn() {
    return this.$store.state.auth.status.loggedIn;
    },
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
    checkComponent(){
     return this.$route.name;
       },
     showHrBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_HR');
      }

      return false;
    },
    ///
   showOpBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_OP');
      }

      return false;
    },
showManagerBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MANAGER');
      }

      return false;
    },




    ////
    showMaintBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MAINT');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR');
      }

      return false;
    },
}
// }
});

</script>
