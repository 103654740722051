import UserService from '../services/auth.service';

export const request = {
  namespaced: true,
  state: {
    requests:'',
  },
  actions: {
    getRequsts(context, request) {
      return $socketio.on('CH01',(request)=>{
        //.then(
        // data => {
          commit('getRequests', data);
        //   alert(data);
        //   return Promise.resolve(data);

        },
        error => {
          commit('errRequests');
          //return Promise.reject(error);
        }
      );
    },


  },
  mutations: {
    getRequests(state,request) {
      state.requests = request;

    },
    errRequest(state) {

    },

  }
};

// this.$soketio.on('CH01', (data) => {
//   this.msg=(data);
// })
